import React from "react"
import Img from "gatsby-image"
import { Link } from 'gatsby'

const CollectionList = ({collections}) => {
  return (
    <div className="row">
      {collections.map((collection, i) => (
        <div className="col-lg-6" key={i}>
          <div className="card card-dark text-white mb-4 overflow-hidden">
            <Link
              to={`/${collection.slug}`}
              className="text-decoration-none"
            >
              <Img className="card-img" fluid={collection.coverImage.fluid} />
            </Link>
            <div className="card-img-overlay d-flex flex-column">
              <div className="media align-items-start mt-auto">
                <Img className="mr-2 rounded" fixed={collection.author.avatar.fixed} />
                <div className="media-body">
                  <div className="small text-light line-height-1 mb-1">
                    by {collection.author.name}
                  </div>
                  <h5 className="m-0">
                    <Link
                      to={`/${collection.slug}`}
                      className="text-decoration-none text-white"
                    >
                      {collection.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CollectionList;