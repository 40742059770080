import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Collections from '../components/collections'

const RootIndex = ({data}) => {
  const site = data.site.siteMetadata;
  const collectionList = data.allContentfulCollection.nodes;

  return (
    <Layout
      title={site.title}
      description={site.description}
      url={site.url}
    >
      <section className="section-hero py-5">
        <div className="container">
          <h1 className="font-weight-light mb-4">Explore guides</h1>
          <Collections collections={collectionList} />
        </div>
      </section>
    </Layout>
  )
}


export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    allContentfulCollection {
      nodes {
        title
        slug
        coverImage {
          fluid(maxWidth: 690) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        author {
          name
          slug
          avatar {
            fixed(width: 40, height: 40) {
              ...GatsbyContentfulFixed_noBase64
            }
          }
        }
      }
    }
  }
`
